import React from "react";
import "./About.css";
import Rectangle1 from "./Rectangle1";
import profile from "../assets/peep2.png";
import peep from "../assets/peep.png";
import Fade from "react-reveal/Fade";
import rec from "../assets/rec.jpg";

export default class About extends React.Component {
  render() {
    return (
      <div className="about">
        <div className="container">
          <div>
            <img className="image" src={profile} alt="profile"></img>
            <img className="image2" src={peep} alt="profile"></img>
          </div>
          {/* <Fade right cascade> */}
          <div className="mobileContainer">
            <div className="aboutH">
              <Fade right cascade>
                <h2 className="heading" style={{ zIndex: 2, top: "5% " }}>
                  About Me
                </h2>
                {/* <Rectangle1></Rectangle1> */}
              </Fade>
              <img src={rec} alt="rec" className="rec"></img>
            </div>
            <Fade right>
              <p className="para">
                Hi, I'm Kartik! I am currently an undergrad, studying
                Architecture at{" "}
                {/* <span style={{ color: "#31708E", fontWeight: "500" }}> */}
                IIT Kharagpur.
                {/* </span> */}
                <br /> I am curious about emerging technologies and intrigued by
                the design of things around me and how it affects the human
                behaviour. I use a blend of code and design to make digital
                products or simply beautiful stuff.
                <br />
                Your can find my Resume{" "}
                <a
                  href="./CV_Kartik.pdf"
                  style={{ textDecorationColor: "#7d7968" }}
                >
                  <span
                    style={{
                      // textDecoration: "underline",
                      // textDecorationColor: "#7d7968",
                      color: "#7d7968",
                      fontWeight: "bold"
                    }}
                  >
                    here
                  </span>
                </a>
                .
              </p>
            </Fade>
          </div>
          {/* </Fade> */}
        </div>
      </div>
    );
  }
}
